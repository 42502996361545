/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import isValidConfig from './helpers/isValidConfig';

export default class IndexedDB {
	/**
	 * Creates an instance of IndexedDB
	 * @param {Object} storageConfig - Configuration options
	 * @example
	 * const Dates = new IndexedDB({
			name: 'filtersStorage',
			version: 1,
			options: {
				objectStores: [{ name: 'filters', config: {} }]
			},
			transactionConfig: {
				objectStoreName: 'filters'
			}
		});
	 */
	constructor(storageConfig) {
		this.isValidInstance = false;

		const isAvailableInBrowser = this._isAvailable();

		if (!isAvailableInBrowser) return;

		this._removeCurrentDatabase('filtersStorage');

		const isConfigValid = isValidConfig(storageConfig);

		if (!isConfigValid) return;

		const { name, version, options, transactionConfig } = storageConfig;

		if (!name || !version) {
			console.error("IndexedDB - Name or version doesn't provided");
			return;
		}

		this._init(name, version, options);
		this.transactionConfig = transactionConfig;
		this.isValidInstance = true;
	}

	/**
	 * Check if IndexedDB is available in the browser
	 * @returns {boolean} True if the storage is available, otherwise false
	 */
	_isAvailable() {
		if (!window.indexedDB) {
			// eslint-disable-next-line no-console
			console.warn('IndexedDb is not available in this browser');
			return false;
		}
		return true;
	}

	// Temporal method, only use for remove current database in beta environment
	async _removeCurrentDatabase(databaseName) {
		const databases = await this._getDatabases();
		const filtersStorageDatabase = databases.find(db => db.name === databaseName);
		if (!filtersStorageDatabase) return;
		this.deleteDatabase(databaseName);
	}

	async _getDatabases() {
		try {
			const databases = await indexedDB.databases();
			return databases;
		} catch (error) {
			console.error(error.message);
			return [];
		}
	}

	/**
	 * Open the indexedDB database in browser and load the configuration
	 * @param {string} name - Database name
	 * @param {number} version - Database version
	 * @param {object} options - Object stores configuration
	 * @example
	 * this._init('filtersStorage', 1, { objectStores: [{ name: 'filters', config: {} }] });
	 */
	_init(name, version, options) {
		this.openRequest = indexedDB.open(name, version);
		this.loadedDb = this._load(options);
	}

	/**
	 * Load database configuration using openRequest property. This property contains indexeDB methods
	 * to handle the database opening
	 * @param {object} options - Object stores configuration
	 * @example
	 * this._load({ objectStores: [{ name: 'filters', config: {} }] });
	 */
	_load(options) {
		this.openRequest.onupgradeneeded = () => {
			const { objectStores = [] } = options || {};
			this._createObjectStores(objectStores);
		};

		this.openRequest.onerror = event => {
			// eslint-disable-next-line no-console
			console.error(`IndexedDB error: ${event.target.errorCode}`);
		};

		this.openRequest.onsuccess = event => {
			this.db = event?.target?.result;
		};
	}

	/**
	 * Get the database object. If the object is not loaded, it will return the loadedDb object.
	 * @return {object} the database object
	 */
	_getDB() {
		if (!this.db) this.db = this.loadedDb;
		return this.db;
	}

	/**
	 * Get the database object. If the object is not loaded, it will return the loadedDb object.
	 * @param {object} objectStores object stores to create a collection of data to save in the database
	 * @example
	 * this._createObjectStores([{ name: 'filters', config: {} }])
	 */
	_createObjectStores(objectStores) {
		if (!objectStores.length) return;
		const db = this.openRequest.result;
		objectStores.forEach(
			({ name, config: { keyPath = 'id', autoIncrement = false, indexes = [] } }) => {
				const store = db.createObjectStore(name, {
					keyPath,
					autoIncrement
				});
				this._createIndexes(store, indexes);
			}
		);
	}

	/**
	 * Creates indexes in the database object store
	 * @param {object} store database object store
	 * @param {array} indexes - array of indexes to create
	 * @example
	 * this._createIndexes(store, [{ name: 'index1', tags: ['index', 'index1'], unique: false }])
	 */
	_createIndexes(store, indexes) {
		if (!indexes || !indexes.length) return;

		indexes.forEach(({ name, tags, unique }) => {
			store.createIndex(name, [...tags], { unique });
		});
	}

	/**
	 * Create a transaction to interact with the database object store
	 * @param {string} objectStoreName database object store name
	 * @param {string} mode - transaction mode
	 * @return {object} transaction object
	 * @example
	 * this._createTransaction('filters', 'readwrite')
	 */
	_createTransaction(objectStoreName, mode = 'readwrite') {
		if (!objectStoreName) throw new Error('IndexedDB - "objectStoreName" is required');
		return this._getDB().transaction(objectStoreName, mode);
	}

	/**
	 * Gets and returns a database object store to use in the get, set and clearAll methods
	 * @return {object} database object store
	 */
	_getObjectStore() {
		const { objectStoreName, mode } = this.transactionConfig || {};
		if (!objectStoreName)
			throw new Error('IndexedDB - No "objectStoreName" specified in transactionConfig');
		const transaction = this._createTransaction(objectStoreName, mode);
		return transaction.objectStore(objectStoreName);
	}

	/**
	 * Returns a promise to gets data using the view key. The store object is used to get the data through events handlers
	 * @param {string} viewKey - key to get the data
	 * @return {Promise} a promise that can return the data filtered by provided key or an error message
	 * @example
	 * const storage = new IndexedDB(config);
	 * storage.get('tms-route-browse');
	 */
	get(viewKey) {
		if (!viewKey) throw new Error('IndexedDB - Empty view key');

		return new Promise((resolve, reject) => {
			const store = this._getObjectStore();

			if (!store) throw new Error('IndexedDB - No store found');

			const data = store.get(viewKey);

			data.onsuccess = () => {
				const result = data?.result;
				resolve(result);
			};

			data.onerror = err => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`IndexedDB - Error on get data ${err}`);
			};
		});
	}

	/**
	 * Save database data. The store object is used to set the data through events handlers
	 * @param {object} dataToSave - data to save in the database
	 * @return {object} a promise that can return an success message or an error message
	 * @example
	 * const storage = new IndexedDB(config);
	 * storage.set(
	 		{
				id: 'tms-route-browse',
				filters: { 
					driverId: 'driverTestId',
	 				warehouseIds: ['warehouse1', 'warehouse2'] 
				} 
			}
		);
	 */
	set(dataToSave) {
		if (!dataToSave) throw new Error('IndexedDB - No data to save');

		return new Promise((resolve, reject) => {
			const store = this._getObjectStore();

			if (!store) throw new Error('IndexedDB - No store found');

			const request = store.put(dataToSave);

			request.onsuccess = () => {
				const result = request?.result;
				resolve(result);
			};

			request.onerror = event => {
				reject(event.target.errorCode);
			};
		});
	}

	/**
	 * Clear all data in database using a view key. The store object is used to set the data through events handlers
	 * @param {string} viewKey - key to get the data
	 * @return {object} a promise that can return an success message or an error message
	 * @example
	 * const storage = new IndexedDB(config);
	 * storage.clearAll('tms-route-browse');
	 */
	clearAll(viewKey) {
		if (!viewKey) throw new Error('IndexedDB - Empty view key');

		return new Promise((resolve, reject) => {
			const store = this._getObjectStore();

			if (!store) throw new Error('IndexedDB - No store found');

			const request = store.delete(viewKey);

			request.onsuccess = () => {
				const result = request?.result;
				resolve(result);
			};

			request.onerror = event => {
				reject(event.target.errorCode);
			};
		});
	}

	/**
	 * Close the database using the loadedDb object and the indexedDB method
	 */
	close() {
		const db = this.loadedDb;
		db.close();
	}

	/**
	 * Delete the database from browser using indexedDB method
	 * @param {string} name - database name
	 * @example
	 * const storage = new IndexedDB(config);
	 * storage.deleteDatabase('filtersStorage');
	 */
	deleteDatabase(name) {
		if (!name) throw new Error('IndexedDB - Database name is required to delete');
		indexedDB.deleteDatabase(name);
	}
}
