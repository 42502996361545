import { isEqual, merge } from 'lodash';
import { format, isValid } from 'date-fns';
import { isEmptyValue, isObject } from 'utils';
import { getCurrentHistory } from 'utils/location';
import { getQueryFiltersParameters } from 'utils/request';
import { isNumber } from 'utils/number';
import { datePresets } from 'utils/dates';
import { handleFetchData } from './fetch';

const history = getCurrentHistory();

export const getCurrentValue = option =>
	isObject(option) && 'value' in option ? option.value : option;

export const getCurrentBrowse = (state, currentBrowse) => state.browses[currentBrowse];

export const mapFilters = filters => {
	const getValue = value => {
		if (Array.isArray(value)) return value.map(val => getValue(val));

		return value instanceof Object && 'value' in value ? value.value : value;
	};

	return Object.entries(filters).reduce(
		(acc, [filterName, value]) => ({
			...acc,
			[filterName]: getValue(value)
		}),
		{}
	);
};

export const makeFetchDataParams = (state, page) => {
	const { sortBy } = state;

	const sortingUrl = () => {
		if (sortBy && sortBy.length === 1)
			return {
				sortBy: sortBy[0].name || sortBy[0].value,
				sortDirection: sortBy[0].initialSortDirection
			};

		const sortingSelected = {
			sortBy: [],
			sortDirection: []
		};

		if (sortBy && sortBy.length) {
			sortBy.forEach(item => {
				sortingSelected.sortBy.push(item.value || item.name);
				sortingSelected.sortDirection.push(item.initialSortDirection);
			});
		}

		return sortingSelected;
	};

	const sortAttrs = sortingUrl() || {};

	const { endpointParameters, currentViewData } = state.schema;

	const currentFilters = {
		filters: mapFilters(state.appliedFilters),
		page,
		...sortAttrs
	};

	const filters = getQueryFiltersParameters(endpointParameters, currentViewData, true);

	return merge(currentFilters, filters);
};

/**
 * History listener for refresh filters and data
 */
export const historyListener = currentBrowse => dispatch => {
	const currentPath = window.location.pathname;
	let currentLocationSearch = null;

	const unlisten = history.listen((location, action) => {
		const { pathname, search } = location;

		//	If page is changed call unlisten() for remove listener created.
		if (action === 'PUSH' && pathname !== currentPath) {
			currentLocationSearch = null;
			return unlisten();
		}

		// If change history by arrows in browser call handleFetchData() for update filters applyed and data
		if (action === 'POP' && pathname === currentPath && currentLocationSearch !== search) {
			dispatch(handleFetchData(false)(currentBrowse));
		}

		currentLocationSearch = search;
	});
};

const sortArray = arrayValue => {
	if (!Array.isArray(arrayValue) || !arrayValue.length) return [];
	return arrayValue.sort();
};

const hasSameArrayValues = (firstArrayValue, secondArrayValue) => {
	if (!Array.isArray(firstArrayValue) || !Array.isArray(secondArrayValue)) return false;

	return sortArray(firstArrayValue).every(
		(value, idx) => value === sortArray(secondArrayValue)[idx]
	);
};

const parseValueToString = value => (isNumber(value) ? String(value) : value);

const filterIsTypeDate = value => (value?.from && value?.to) || isValid(value);

const checkDateValueIsPreset = (dateStart, dateEnd) => {
	if (!isValid(new Date(dateStart)) || !isValid(new Date(dateEnd))) return;

	return Object.values(datePresets).find(preset => {
		const presetStart = preset?.start.format('YYYY-MM-DD');
		const presetEnd = preset?.end.format('YYYY-MM-DD');

		return isEqual(presetStart, dateStart) && isEqual(presetEnd, dateEnd);
	});
};

const getDateValue = value => {
	const dateStart = format(new Date(value?.from), 'yyy-MM-dd');
	const dateEnd = format(new Date(value?.to), 'yyy-MM-dd');

	const selectedPreset = checkDateValueIsPreset(dateStart, dateEnd);
	const dateIsPreset = !!selectedPreset;

	if (!dateIsPreset) return value;

	const { startFilter, endFilter } = selectedPreset || {};

	const isDateRange = value?.from && value?.to;
	return isDateRange ? { from: startFilter, to: endFilter } : startFilter;
};

export const getValueFromFilter = filterValue => {
	if (filterIsTypeDate(filterValue)) return getDateValue(filterValue);

	if (Array.isArray(filterValue))
		return filterValue.map(filter => parseValueToString(filter?.value));

	return parseValueToString(filterValue?.value);
};

export const getFiltersValues = filters =>
	Object.entries(filters).reduce((acc, [filterName, filterValue]) => {
		acc[filterName] =
			typeof filterValue === 'string' ? filterValue : getValueFromFilter(filterValue);
		return acc;
	}, {});

const hasSameDateValues = (firstValue, secondValue) => {
	if (!firstValue || !secondValue) return false;
	return firstValue?.from === secondValue?.from && firstValue?.to === secondValue?.to;
};

export const checkFilterValues = (firstValue, secondValue) => {
	if (filterIsTypeDate(firstValue)) return hasSameDateValues(firstValue, secondValue);
	return Array.isArray(firstValue)
		? hasSameArrayValues(firstValue, secondValue)
		: firstValue === secondValue;
};

const getExclusiveFilters = (filtersSourceObj, filtersObjToValidate) => {
	if (!isObject(filtersSourceObj) || !isObject(filtersObjToValidate)) return {};

	return Object.keys(filtersSourceObj).reduce((acc, key) => {
		if (!(key in filtersObjToValidate)) acc[key] = filtersSourceObj[key];
		return acc;
	}, {});
};

const getFiltersBySource = ({ urlFilters, defaultFilters, storagedFilters }) =>
	Object.keys(urlFilters).reduce((acc, filterName) => {
		const filterValue = urlFilters[filterName];

		const existsInDefault = filterName in defaultFilters;
		const existsInStorage = filterName in storagedFilters;

		if (existsInStorage || (!existsInStorage && !existsInDefault)) acc[filterName] = filterValue;

		if (existsInDefault) {
			const defaultValue = defaultFilters[filterName];
			const hasSameValueAsDefault = checkFilterValues(filterValue, defaultValue);

			acc[filterName] = !hasSameValueAsDefault ? filterValue : defaultValue;
		}

		return acc;
	}, {});

export const getFiltersValuesFromSources = ({
	filtersFromUrl,
	defaultFilters = {},
	storagedFilters = {}
}) => {
	if (!filtersFromUrl) return { ...defaultFilters, ...storagedFilters };

	const filtersToSet = getFiltersBySource({
		urlFilters: filtersFromUrl,
		defaultFilters,
		storagedFilters
	});

	const exclusiveFiltersFromStorage = getExclusiveFilters(storagedFilters, filtersFromUrl);
	const exclusiveDefaultFilters = getExclusiveFilters(defaultFilters, filtersFromUrl);

	return {
		...filtersToSet,
		...exclusiveFiltersFromStorage,
		...exclusiveDefaultFilters
	};
};

export const removeFiltersWithEmptyValues = filtersObj => {
	if (!isObject(filtersObj)) return {};

	return Object.keys(filtersObj).reduce((acc, key) => {
		const value = filtersObj[key];
		if (!isEmptyValue(value)) acc[key] = value;
		return acc;
	}, {});
};
