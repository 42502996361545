/* eslint-disable class-methods-use-this */
import IndexedDB from 'libs/IndexedDB';
import { isEmptyValue, isObject } from 'utils';
import removeEmptyFilter from './helpers/removeEmptyFilter';

const storageConfig = {
	name: 'filtersStorageDeprecated',
	version: 1,
	options: {
		objectStores: [{ name: 'filters', config: {} }]
	},
	transactionConfig: {
		objectStoreName: 'filters'
	}
};

class FiltersStorage {
	/**
	 * Creates an instance of FiltersStorage
	 */
	constructor() {
		const storage = new IndexedDB(storageConfig);
		this.storage = storage?.isValidInstance ? storage : null;
	}

	/**
	 * Return a filters object data using a view key
	 * @param {string} viewKey - a view key to get the filters object
	 * @returns {object} the filters object saved in the database
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.getFiltersData('filters');
	 */
	async getFiltersData(viewKey) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			const filtersObj = await this.storage.get(viewKey);
			return filtersObj;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Get filters object error: ${error?.message}`);
			return {};
		}
	}

	/**
	 * Return the filters data using a view key
	 * @param {string} viewKey - a view key to get the filters object
	 * @returns {object} the filters saved by key in the database
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.getFilters('filters');
	 */
	async getFilters(viewKey) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			const result = await this.getFiltersData(viewKey);
			return result?.filters || {};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Error at get filters: ${error?.message}`);
			return {};
		}
	}

	/**
	 * Checks if a filter does not exist in the schema and removes it from the filters object to save in database
	 * @param {object} filtersToSave - the filters to save in database
	 * @param {object} schemaFilters - the filters declared in the page schema
	 * @returns {object} the filters to save in database without the filters that do not exist in the schema
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.removeUnavailableFiltersFromSchema({ driverId: 'driverTestId' }, []);
	 */
	removeUnavailableFiltersFromSchema(filtersToSave, schemaFilters) {
		if (!isObject(filtersToSave) || !schemaFilters.length) return {};

		Object.keys(filtersToSave).forEach(filter => {
			if (!schemaFilters.some(schemaFilter => schemaFilter.name === filter)) {
				// eslint-disable-next-line no-param-reassign
				delete filtersToSave[filter];
			}
		});
		return filtersToSave;
	}

	/**
	 * Insert filters in the database using the view key
	 * @param {string} filtersToSaveConfig.viewKey - the view key in database
	 * @param {object} filtersToSaveConfig.filtersToSave - the filters to save in database
	 * @param {boolean} filtersToSaveConfig.isSingleFilter - True if only save a one filter, otherwise save many filters
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.insertFilters({ viewKey: 'filters', filtersToSave: { driverId: 'driverTestId' }, isSingleFilter: true });
	 */
	async insertFilters({ viewKey, filtersToSave, isSingleFilter }) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			const filtersInStorage = await this.getFilters(viewKey);

			const newFiltersToSaveObj = Object.entries(filtersToSave).reduce((acc, [name, value]) => {
				// eslint-disable-next-line no-param-reassign, no-return-assign
				if (!value || isEmptyValue(value)) return (acc = removeEmptyFilter(filtersInStorage, name));

				acc[name] = value;
				return acc;
			}, {});

			const filtersToSaveInStorage = {
				...(isSingleFilter && { ...filtersInStorage }),
				...newFiltersToSaveObj
			};

			await this.storage.set({ id: viewKey, filters: filtersToSaveInStorage });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Error at insert filters: ${error?.message}`);
		}
	}

	/**
	 * Remove one filter from the filters object in the database using the view key
	 * @param {string} viewKey - a view key to search in database
	 * @param {string} filterKey - a filter key to remove from the filters object
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.clearFilter('filters', 'driverId');
	 */
	async clearFilter(viewKey, filterKey) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			const filtersInStorage = await this.getFilters(viewKey);

			delete filtersInStorage[filterKey];

			await this.storage.set({ id: viewKey, filters: filtersInStorage });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Error at clear filter: ${error?.message}`);
		}
	}

	/**
	 * Remove all filters from the filters object using a view key
	 * @param {string} viewKey - a view key to search in database
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.clearFilters('filters');
	 */
	async clearFilters(viewKey) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			await this.storage.set({ id: viewKey, filters: {} });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Error at clear filters: ${error?.message}`);
		}
	}

	/**
	 * Remove all the data storaged in the database, using the view key
	 * @param {string} viewKey - a view key to search in database
	 * @example
	 * const storage = new FiltersStorage();
	 * storage.removeViewKeyFromStorage('filters');
	 */
	async removeViewKeyFromStorage(viewKey) {
		try {
			if (!this.storage) throw new Error('Storage is not available');

			await this.storage.clearAll(viewKey);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`FiltersStorage - Error at remove view key from storage: ${error?.message}`);
		}
	}
}

export default new FiltersStorage();
