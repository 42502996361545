/**
 * Removes an empty filter from the storage.
 * @param {Object} filtersInStorage - The object containing all filters in storage.
 * @param {string} filterName - The name of the filter to remove.
 * @returns {Object} The updated filters storage object.
 */
export default (filtersInStorage, filterName) => {
	// eslint-disable-next-line no-param-reassign
	delete filtersInStorage[filterName];
	return filtersInStorage;
};
