import { parse } from 'qs';
import { makeFetchDataParams } from 'utils';
import { getComponentsByName, modifyParams, updateUrlFilters } from 'utils/location';

const mapDefaultValues = ({ filters, isBrowse = false }) => {
	const defaultValuesSchema = {};
	const paramObj = parse(window.location.search, { ignoreQueryPrefix: true });

	const filtersWithDefaultValue = filters?.length && filters.filter(filter => filter?.defaultValue);

	if (filtersWithDefaultValue?.length) {
		filtersWithDefaultValue.forEach(filter => {
			defaultValuesSchema[filter.name] = filter.defaultValue;
		});
		if (!paramObj?.filters && !isBrowse) {
			const defaultValuesParams = makeFetchDataParams(defaultValuesSchema);
			updateUrlFilters(defaultValuesParams, filters);
		}
		const componentsByName = getComponentsByName(filters);
		const formattedDefaultValues = modifyParams(defaultValuesSchema, componentsByName, true);
		return formattedDefaultValues;
	}
	return {};
};

export default mapDefaultValues;
