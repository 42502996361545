import { toNumber, get as lodashGet, isEmpty } from 'lodash';
import { stringify } from 'qs';
import {
	formattedEndpointParameters,
	filtersFormatted,
	getPathFiltersParameters,
	getQueryFiltersParameters
} from 'utils/request';
import { getLocationParts } from 'utils/location';
import { processConditionals } from 'utils/conditions';
import { isTranslationKey } from 'utils/string';
import { findColorInTheme } from 'theme';
import { translateHelperValue as translateHelper } from 'utils/translate';
import { findMapper, map } from 'utils/mappers';

let lastId = 0;

/**
 * @description Return an unique id incrementally
 * @param {string} [prefix='id'] prefix of id
 * @returns {string}
 */
export const newId = (prefix = 'id') => `${prefix}${++lastId}`;

/**
 * @description Reset the number of id - only used in test
 */
export const testOnlyResetId = () => {
	lastId = 0;
};

// compose :: ((a -> b), (b -> c),  ..., (y -> z)) -> a -> z
export const compose = (...fns) => (...args) =>
	fns.reduceRight((res, fn) => [fn.call(null, ...res)], args)[0];

export const increment = a => a + 1;

export const decrement = a => a - 1;

/**
 * Copy string to clipboard
 * @param {string} str
 */
/* istanbul ignore next */
export const copyToClipboard = /* istanbul ignore next */ str => {
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

/**
 * animated scrollToTop with ease in and out timing function
 * @param {number} scrollDuration - duration in milliseconds
 * reference https://stackoverflow.com/a/24559613
 */
export /* istanbul ignore next */ function scrollToTop(scrollDuration = 500) {
	const cosParameter = window.scrollY / 2;
	let scrollCount = 0;
	let oldTimestamp = performance.now();

	function step(newTimestamp) {
		scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
		if (scrollCount >= Math.PI) window.scrollTo(0, 0);
		if (window.scrollY === 0) return;
		window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
		oldTimestamp = newTimestamp;
		window.requestAnimationFrame(step);
	}
	window.requestAnimationFrame(step);
}

/**
 * Creates a debounced function that delays invoking func until after wait milliseconds have
 * elapsed since the last time the debounced function was invoked.
 * @param {function} fn - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {function} The new debounced function.
 */
export function debounce(fn, wait) {
	let timerID;
	function debounced(...params) {
		const context = this;

		clearTimeout(timerID);
		timerID = setTimeout(fn.bind(context), wait, ...params);
	}

	debounced.cancel = function cancel() {
		clearTimeout(timerID);
	};

	return debounced;
}

/**
 * Creates a throttled function that only invokes the provided function at most once per every wait milliseconds
 * @param {function} fn - The function to throttle.
 * @param {number} wait - The number of milliseconds to throttle invocations to.
 * @returns The new throttled function.
 */
export function throttle(fn, wait) {
	let ticking = false;
	let timerID;

	function throtteled(...params) {
		const context = this;
		if (!ticking) {
			fn.call(context, ...params);
			ticking = true;

			timerID = setTimeout(() => {
				ticking = false;
			}, wait);
		}
	}

	throtteled.cancel = function cancel() {
		clearTimeout(timerID);
	};

	return throtteled;
}

/**
 * Creates a throttled function that only invokes the provided function at most once
 * per every 16 milliseconds (request animation frame)
 * @param {function} fn - The function to throttle.
 * @returns {function} The new throttled function.
 */
export /* istanbul ignore next */ function rAF(fn) {
	let ticking = false;

	return function raf(...params) {
		// eslint-disable-line func-names
		const context = this;
		if (!ticking) {
			ticking = true;

			requestAnimationFrame(() => {
				fn.call(context, ...params);
				ticking = false;
			});
		}
	};
}

export const hasKeys = value => typeof value === 'object' && Object.keys(value).length;

/**
 * Check if value is a Object
 * @param {any} value
 */
export const isObject = value =>
	typeof value === 'object' && !Array.isArray(value) && value instanceof Object;

const protocolRegexp = /^[a-z0-9.]+:\//;

/**
 * Check if a string starts with a protocol, for example http://, https:// or native APP protocols like com.janis.picking:/callback
 * @param {string} string
 * @returns {boolean}
 */
export const hasProtocol = string => !!string.match(protocolRegexp);

/**
 * Format object|string value to object translated valid for React-Select(value || option)
 * @param {object} option
 * @param {function} t
 */
export const getValueForSelect = option => {
	const { value, label } = option;
	return { label: translateHelper(label), value };
};

/* export const logger = (title = 'logg', ...content) => {
	if (process.env.NODE_ENV !== 'development') return;
	console.group(`%c${title}`, 'background: #1DB779; color: #fff; padding: 2px 80px;');
	console.log(...content);
	console.groupEnd();
}; */

export const parseValue = value => {
	if (typeof value === 'object' || value === '') return value;

	const currentValue = toNumber(value);

	return Number.isNaN(currentValue) ? value : currentValue;
};

/**
 * Make a object with filters params
 * @param {object} filtersForMake
 * @return
 * {
 * 		filters: {
 * 			date_from: '1/1/1',
 * 			id: 1
 * 		}
 * }
 */
export const makeFetchDataParams = filtersForMake => {
	const mapFilters = filters => {
		const getValue = value => {
			if (Array.isArray(value)) return value.map(val => getValue(val));

			return value instanceof Object && 'value' in value ? value.value : value;
		};

		return Object.entries(filters).reduce(
			(acc, [filterName, value]) => ({
				...acc,
				[filterName]: getValue(value)
			}),
			{}
		);
	};

	const filters = { ...filtersForMake };

	return { filters: mapFilters(filters) };
};

/**
 * Url endpoint parameters replacer
 * @param {string} endpoint
 * @param {object} endpointParameters
 */
export const getUrlWithEndpointParameters = (endpoint, endpointParameters = {}) =>
	endpointParameters
		? Object.keys(endpointParameters).reduce(
				(accum, endpointParam) =>
					accum.replace(`{${endpointParam}}`, endpointParameters[endpointParam]),
				endpoint
		  )
		: endpoint;

/**
 * Helper for make async loops
 * @param {array} array
 * @param {function} callback
 */
export const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		// eslint-disable-next-line no-await-in-loop
		await callback(array[index], index, array);
	}
};

export const mappedDataForActionButtons = (actions, data = {}, isNewType = false) =>
	actions.map(action => {
		const isFormType = action.type === 'form';
		const attrName = isNewType ? 'componentAttributes' : 'options';

		const attributes = action[attrName] || {};

		const { endpointParameters: rawEndpointParameters, endpoint = {} } = attributes;

		const { method: apiMethod } = endpoint;

		const method = (apiMethod || 'get').toLowerCase();

		const endpointParameters = !isFormType
			? formattedEndpointParameters(rawEndpointParameters, data)
			: rawEndpointParameters;

		const filters = !isFormType ? filtersFormatted(rawEndpointParameters, method, data) : null;

		return {
			...action,
			[attrName]: {
				...(attributes || {}),
				info: {},
				endpointParameters,
				filters,
				data
			}
		};
	});

export const makeTemplateValueDefault = (template, fields) =>
	fields.reduce(
		(acum, replacement, index) =>
			acum.replace(`{${index}}`, replacement || replacement === 0 ? replacement : ''),
		template
	);

/**
 * Add new param fields to current params and return them
 * @param {string} string
 * @param {string} currentParams
 * @param {array} currentFields
 * @returns {string} updated params
 * @example
 * const currentParams = 'fields[0]=name';
 * const currentFields = [name];
 * const newQueryString = addStringParam('id', currentParams, currentFields);
 * // result: 'fields[0]=name&fields[1]=id'
 */
const addStringParam = (string, currentParams, currentFields) => {
	const idx = currentFields.length;

	currentFields.push(string);

	return `${currentParams && '&'}fields[${idx}]=${string}`;
};

const extractFields = value => {
	if (typeof value === 'string') return [value];
	if (isObject(value)) {
		const { fields = [] } = value;
		const parseFields = fields.map(field => (!isObject(field) ? field : field?.name));

		return parseFields;
	}
	return [];
};

/**
 * Get fields from valuesMapper
 * @param {object} valuesMapper
 * @returns {string} updated fields params
 * @example
 * const valuesMapper = { "label": "name", "value": "ecommerceName" }
 */
export const getFieldsQueryFromMapper = valuesMapper => {
	const { label = 'name', value = 'id' } = valuesMapper || {};
	const currentFieldsForIdx = [];

	const labelFields = extractFields(label);
	const valueFields = extractFields(value);

	const mergeFields = [...labelFields, ...valueFields];

	const fieldsParams = mergeFields.reduce(
		(params, field) => params + addStringParam(field, params, currentFieldsForIdx),
		''
	);

	return fieldsParams;
};

/**
 * String Template Formatter
 * @param {object} mapperTemplate
 * @param {object} values
 */
export const formatMapperTemplate = (mapperTemplate, values, makeTemplateValueExt) => {
	const { template, fields = [], makeTemplateValue = () => {} } = mapperTemplate;
	const makeTemplateValFn = makeTemplateValueExt || makeTemplateValue;

	const isSelectVal = val =>
		val !== null &&
		typeof val === 'object' &&
		Object.keys(val).length <= 4 &&
		'value' in val &&
		'label' in val;

	const getCurrentValue = (fieldName, mapper) => {
		const fieldValue = lodashGet(values, fieldName);

		const allMappers = Array.isArray(mapper) ? mapper : [mapper];

		const translateMapper = allMappers && allMappers.find(findMapper('translate'));

		let currentValue = mapper ? map(mapper, fieldValue, values) : fieldValue;

		if (translateMapper) {
			const prefixMapper = allMappers.find(findMapper('prefix'));

			const key = prefixMapper ? map(prefixMapper, fieldValue) : fieldValue;

			currentValue = {
				value: currentValue,
				key
			};
		}

		return isSelectVal(currentValue) ? currentValue.label || currentValue.value : currentValue;
	};

	const currentFields = fields.map(field => {
		if (isObject(field) && !isEmpty(field)) {
			const { name: fieldName, mapper, conditions } = field;
			const { showWhen } = conditions || {};
			let show = true;

			if (showWhen)
				show = processConditionals({
					andConditionals: showWhen,
					data: values,
					matchFieldName: fieldName
				});

			if (!show) return '';

			return getCurrentValue(fieldName, mapper);
		}

		return getCurrentValue(field);
	});

	return (
		makeTemplateValFn(template, currentFields) || makeTemplateValueDefault(template, currentFields)
	);
};

/** Get attributes (such as color or icon names) from themes or return the specified one.
 * @param {object|string} attribute - Either the attribute object with the theme name under the useTheme prop,
 * or a string with the prop name.
 * @param {object} themes - The themes object
 * @param {string} value - The value to get the theme match
 */
export const getThemeValue = (attribute, themes, value, schemaColor) => {
	if (isObject(attribute)) {
		const { useTheme } = attribute;

		const themeValue = themes[useTheme]
			? themes[useTheme][value] || schemaColor || themes[useTheme]._default // eslint-disable-line no-underscore-dangle
			: undefined;

		return themeValue;
	}

	return attribute;
};

/** Find the theme that match the condition and returns the name
 * @param {object} themeConditionals - The themeConditionals object
 * @param {object} data - The data to get the condition match
 */
export const getConditionalTheme = (themeConditionals, data, fieldDataName) => {
	if (!themeConditionals) return null;
	const [conditionalThemeName] = Object.entries(themeConditionals).find(([, theme]) =>
		processConditionals({ andConditionals: theme, data, matchFieldName: fieldDataName })
	) || ['_default'];

	return conditionalThemeName;
};

/**
 * Get service and namespaces values from schema or url
 * @param {object} schema
 */
export const getServiceNamepaceValues = (schema = {}) => {
	const { target = {}, source = {}, service } = schema;
	const [serviceUrl, namespaceUrl] = getLocationParts();

	return {
		service: source.service || target.service || service || serviceUrl,
		namespace: source.namespace || target.namespace || namespaceUrl
	};
};

/**
 * returns the url for an image, from an object or string
 * @param {object||string} item
 */
export const getUrlForImage = item => {
	if (item) return typeof item === 'string' ? item : item.url || '';
	return '';
};

/** Get attributes (such as color or icon names) from themes and return the specified one.
 * @param {object} attribute - The attribute object with the theme name under the useTheme prop,
 * @param {object} themes - The themes object
 * @param {string} value - The value to get the theme match
 */
export const getThemeObject = (attributes, themes, value) => {
	if (!themes) return {};

	const { useTheme } = attributes;

	const theme = themes[useTheme];

	const themeObject = theme && theme[value];

	// eslint-disable-next-line dot-notation
	const defaultValue = theme && theme['_default'];

	return themeObject || defaultValue || {};
};

/**
 * @param {object} currentHour - number between 00 and 24 ,
 */
export const getGreetingTime = currentHour => {
	const isValidHour = currentHour >= 0 && currentHour <= 24;

	if (!isValidHour) return 'common.greetings.hello';

	const splitMorning = 4; // 24hr time to split the morning
	const splitAfternoon = 12; // 24hr time to split the afternoon
	const splitEvening = 19; // 24hr time to split the evening

	// Between 4AM and 12PM
	if (currentHour >= splitMorning && currentHour < splitAfternoon)
		return 'common.greetings.goodMorning';

	// Between 12PM and 7PM
	if (currentHour >= splitAfternoon && currentHour < splitEvening)
		return 'common.greetings.goodAfternoon';

	// Between 7PM and 4AM
	return 'common.greetings.goodEvening';
};

/** Given a background-color returns the font-color by contrast
 * @param {string} color - a color from palet or hexa
 */
export const getFontColorByContrast = (color = '') => {
	// get the value in hexa
	const formattedColor = findColorInTheme(color);

	const hexa = formattedColor.substring(1);

	const red = parseInt(hexa.substr(0, 2), 16);
	const green = parseInt(hexa.substr(2, 2), 16);
	const blue = parseInt(hexa.substr(4, 2), 16);

	const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

	return brightness < 155 ? '#ffffff' : '#000000';
};

/** Returns the User Created and User Modified fieldsGroups
 * @param {object} data - object containing the user data
 * @param {boolean} hideUserCreated - hide the UserCreated fieldsGroup
 * @param {boolean} hideUserModified - hide the userModified fieldsGroup
 */
export const formatRecordData = (data, hideUserCreated, hideUserModified) => {
	if (!data) return [];

	const { userCreated, dateCreated, userModified, dateModified } = data;

	const baseField = {
		component: 'RecordData',
		noLabel: true
	};

	const userCreatedStructure = {
		fields: [
			{
				...baseField,
				name: 'creationData',
				info: {
					id: userCreated,
					time: dateCreated
				}
			}
		],
		icon: 'user_closed',
		name: 'creatorUser',
		position: 'right'
	};

	const userModifiedStructure = {
		fields: [
			{
				...baseField,
				name: 'modificationData',
				info: {
					id: userModified,
					time: dateModified
				}
			}
		],
		icon: 'edit',
		name: 'lastModification',
		position: 'right'
	};

	const formattedData = [];

	if ((userCreated || dateCreated) && !hideUserCreated) formattedData.push(userCreatedStructure);
	if ((userModified || dateModified) && !hideUserModified)
		formattedData.push(userModifiedStructure);

	return formattedData;
};

export const makePrefixedValue = (fieldName, value, translate, prefix) => {
	if (isTranslationKey(value)) return value;

	const currentPrefix = translate ? prefix || `views.fieldValue.${fieldName}.` : prefix;

	return currentPrefix ? `${currentPrefix}${value}` : value;
};

export const generateCustomRowLink = (linkProps, data) => {
	const {
		path,
		endpointParameters = [
			{
				name: 'id',
				target: 'path',
				value: {
					dynamic: 'id'
				}
			}
		]
	} = linkProps;

	const pathParameters = getPathFiltersParameters(endpointParameters, data) || {};
	const queries = getQueryFiltersParameters(endpointParameters, data);

	let endpointUrl = getUrlWithEndpointParameters(path, pathParameters);

	if (queries) {
		const linkHasQuery = endpointUrl.includes('?');
		endpointUrl = `${endpointUrl}${linkHasQuery ? '&' : '?'}${stringify(queries)}`;
	}

	return endpointUrl;
};

const parseValues = (data = {}, requestFields = {}) => {
	const keys = Object.keys(data);

	const newData = keys.reduce((accum, key) => {
		const acc = { ...accum };
		if (!requestFields[key]) {
			acc[key] = data[key];

			return acc;
		}

		acc[requestFields[key]] = data[key];

		return acc;
	}, {});

	return newData;
};

export const parseDataWithRequestFields = (data, requestFields) => {
	if (isObject(data)) {
		const newData = parseValues(data, requestFields);
		return newData;
	}

	const parsedData = data.reduce((accum, currentData) => {
		const newData = parseValues(currentData, requestFields);

		accum.push(newData);

		return accum;
	}, []);

	return parsedData;
};

export const getObjectKeyByIndex = (object, index = 0) => Object.keys(object)[index];

/**
 * checks if a value is empty, null, or an empty array
 * @param {string|string[]} value - the value to check
 * @return {boolean} true if is an empty value, otherwise false
 */
export const isEmptyValue = value =>
	value === '' || value === null || (Array.isArray(value) && !value?.length);
