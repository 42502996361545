export const CLEAR_DATA = '@browse/CLEAR_DATA';
export const FETCH_DATA_REQUEST = '@browse/FETCH_DATA_REQUEST';
export const FETCH_DATA_FAILURE = '@browse/FETCH_DATA_FAILURE';
export const FETCH_SUCCESS_WITHOUT_TOTAL = '@browse/FETCH_SUCCESS_WITHOUT_TOTAL';
export const FETCH_DEPENDENCIES_SUCCESS = '@browse/FETCH_DEPENDENCIES_SUCCESS';
export const SET_HAS_PREVIEW_SCHEMA = '@browse/SET_HAS_PREVIEW_SCHEMA';
export const SET_HAS_ROW_COLLAPSE_PREVIEW_SCHEMA = '@browse/SET_HAS_ROW_COLLAPSE_PREVIEW_SCHEMA';
export const CHANGE_SELECTED_ROWS = '@browse/CHANGE_SELECTED_ROWS';
export const FETCH_MASSIVE_ACTIONS_SUCCESS = '@browse/FETCH_MASSIVE_ACTIONS_SUCCESS';
export const FETCH_MASSIVE_ACTIONS_REQUEST = '@browse/FETCH_MASSIVE_ACTIONS_REQUEST';
export const FETCH_MASSIVE_ACTIONS_FAILURE = '@browse/FETCH_MASSIVE_ACTIONS_FAILURE';
export const SHOW_MASSIVE_ACTIONS_CHECKBOXES = '@browse/SHOW_MASSIVE_ACTIONS_CHECKBOXES';
export const CHANGE_FILTER = '@browse/CHANGE_FILTER';
export const CHANGE_FILTERS = '@browse/CHANGE_FILTERS';
export const CLEAR_FILTER = '@browse/CLEAR_FILTER';
export const CLEAR_ALL_FILTERS = '@browse/CLEAR_ALL_FILTERS';
export const CHANGE_SORT = '@browse/CHANGE_SORT';
export const SET_EMPTY_BROWSE = '@browse/SET_EMPTY_BROWSE';
export const INIT_BROWSE = '@browse/INIT_BROWSE';
export const CLEAR_BROWSE = '@browse/CLEAR_BROWSE';
export const SET_BROWSE_INITIAL_STATES = '@browse/SET_BROWSE_INITIAL_STATES';
export const USE_URL_UPDATES = '@browse/USE_URL_UPDATES';
export const START_ASYNC_LOADING = '@browse/START_ASYNC_LOADING';
export const STOP_ASYNC_LOADING = '@browse/STOP_ASYNC_LOADING';
export const SET_ASYNC_ERROR = '@browse/SET_ASYNC_ERROR';
export const APPLY_FILTERS = '@browse/APPLY_FILTERS';
export const APPLY_FILTERS_TOGGLE = '@browse/APPLY_FILTERS_TOGGLE';
export const SET_DEFAULTVALUES = '@browse/SET_DEFAULTVALUES';
export const FETCH_TOTAL = '@browse/FETCH_TOTAL';
export const SET_STORAGED_FILTERS_STATE = '@browse/SET_STORAGED_FILTERS_STATE';
