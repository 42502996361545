import mapDefaultValues from 'utils/filters/mapDefaultValues';
import { parse } from 'qs';
import { updateUrlFilters } from 'utils/location';
import { getCurrentBrowse, getFiltersValuesFromSources, historyListener } from '../actions-utils';
import { setDefaultValues } from '../filters';
import { setInitialStates } from '../init';
import { handleFetchData } from './data';

/**
 * Initial request for View Browse
 * Get remote filters, apply filter by url and get registers
 */
export const initialBrowseFetch = currentBrowse => (dispatch, getState) => {
	// Get current remote filters if exist
	const state = getCurrentBrowse(getState(), currentBrowse);

	const { storagedFiltersState } = state;
	const { filters, rootComponent } = state.schema;
	const formattedDefaultValues = mapDefaultValues({ filters, isBrowse: true });

	const urlParamsObj = parse(window.location.search, { ignoreQueryPrefix: true });
	const filtersToSetInUrl = getFiltersValuesFromSources({
		filtersFromUrl: urlParamsObj.filters,
		defaultFilters: formattedDefaultValues,
		storagedFilters: storagedFiltersState?.filters
	});

	if (!rootComponent) updateUrlFilters({ ...urlParamsObj, filters: filtersToSetInUrl }, filters);

	dispatch(setInitialStates(state.schema)(currentBrowse));
	// Add History listener for refresh filters and data
	if (state.useUrlUpdates) dispatch(historyListener(currentBrowse));

	if (Object.keys(formattedDefaultValues)) {
		dispatch(setDefaultValues(formattedDefaultValues)(currentBrowse));
	}
	// Call function for fetch data
	dispatch(handleFetchData(false)(currentBrowse));
};
