/**
 * Validates the storage configuration for IndexedDB.
 *
 * @param {Object} storageConfig - The storage configuration object to validate.
 * @returns {boolean} Returns true if the storage configuration is valid, otherwise false.
 */
export default storageConfig => {
	if (!storageConfig) {
		console.error('IndexedDB - invalid storage config');
		return false;
	}
	return true;
};
